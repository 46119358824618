<template>
  <router-view/>
</template>

<script>
import {GET_USER_INFO} from '@/store/modules/AuthModule';
export default {
  methods:{
    async getUserInfo(){
      await this.$store.dispatch(GET_USER_INFO);
    }
  },
  created() {
    if(this.currentCredentials.token){
      this.getUserInfo()
    }
    //fix el-select
    let fixElSelect = function () {
      document.querySelectorAll('.el-select.customSelectRemote:hover').forEach(() => {
        let elInput = document.querySelector('.el-select.customSelectRemote:hover input[readonly]');
        if (elInput) {
          elInput.readOnly = false;
          elInput.blur();
          elInput.focus();
        }
      });
    };
    document.addEventListener("focusin", fixElSelect);
    document.addEventListener("click", fixElSelect);
    document.addEventListener("touchstart", fixElSelect);
  },
  computed: {
    currentCredentials() {
      return this.$store.getters.get_credentials;
    }
  }
}
</script>