<template>
  <el-header>
    <el-container>
      <el-row :gutter="30" align="middle">
        <el-col :xs="{span:12}" :sm="{span:6}" :md="{span: 4}" class="zIndex2">
          <a href="/" class="logo"><img src="@/assets/img/landing/logo.png" alt=""></a>
        </el-col>
        <el-col :xs="{span: 0}" :sm="{span: 7}" :md="{span: 7}">
        </el-col>
        <el-col :xs="{span:12}" :sm="{span:11}" :md="{span:13}" class="text-right zIndex2">
          <template v-if="this.userInfo">
            <p class="creditsBalance" v-if="this.user_balance!==''">
              {{ balanceFormat(this.user_balance) }}/{{ balanceFormat(10000) }}<b>P</b>
            </p>
            <a href="/cabinet" class="defaultButton mini hiddenForMobile">
              <span>{{ this.overflowText(userInfo.email) }}</span>
            </a>
          </template>
          <a href="/login" class="defaultButton mini" v-else>
            <span>Login</span>
          </a>
        </el-col>
      </el-row>
    </el-container>
  </el-header>
</template>

<script>
import {GET_USER_BALANCE} from "@/store/modules/AuthModule";

export default {
  data:()=>({
    user_balance: '',
  }),
  methods:{
    balanceFormat(data){
      return String(Number(data).toFixed(0)).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    },
    overflowText(text){
      if(text.length > 10){
        text = text.substring(0, 10) + '...';
      }
      return text;
    },
    async getUserBalance(){
      let res = await this.$store.dispatch(GET_USER_BALANCE);
      this.user_balance = res.balance;
    }
  },
  created() {
    if(this.currentCredentials.token){
      this.getUserBalance();
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.get_user_info;
    },
    currentCredentials() {
      return this.$store.getters.get_credentials;
    }
  }
}
</script>
