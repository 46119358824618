<template>
  <div class="newOraclus">
    <el-container class="main-container">
      <HeaderComponent></HeaderComponent>
      <el-main id="mainBlock">
        <router-view></router-view>
      </el-main>
      <el-footer>
        <FooterComponent></FooterComponent>
      </el-footer>
    </el-container>
  </div>

</template>

<script>
import HeaderComponent from '@/views/default-components/HeaderComponent'
import FooterComponent from '@/views/default-components/FooterComponent'
export default {
  name: 'PublicLayout',
  components:{
    HeaderComponent,
    FooterComponent
  }
};
</script>
