import {SecondAPIInstance, ChainsAPIInstance, DefaultAPIInstance} from "@/plugins/axios";
export const SEARCH_BY_ADDRESS = "SEARCH_BY_ADDRESS";
export const REFRESH_BY_ADDRESS = "REFRESH_BY_ADDRESS";
export const GET_TYPE_BY_ADDRESS = "GET_TYPE_BY_ADDRESS";
export const SEARCH_ENS_LABEL_CLOUD = "SEARCH_ENS_LABEL_CLOUD";
export const CHECK_BLOCKCHAINS = "CHECK_BLOCKCHAINS";
export const GET_SEARCH_EXAMPLES = "GET_SEARCH_EXAMPLES";
export const GET_STATISTICS = "GET_STATISTICS";
export const GET_HISTORY_BY_ID = "GET_HISTORY_BY_ID";

const state = {

}

const actions = {
    [SEARCH_BY_ADDRESS]:async (store, search_value)=>{
        const { data }  = await DefaultAPIInstance({url:'/kyt/search/'+search_value+'/',method:'GET'});
        return data;
    },
    [REFRESH_BY_ADDRESS]:async (store, search_value)=>{
        const { data }  = await DefaultAPIInstance({url:'/kyt/search/'+search_value+'/?refresh=true',method:'GET'});
        return data;
    },
    [GET_TYPE_BY_ADDRESS]:async (store, search_value)=>{
        const { data }  = await DefaultAPIInstance({url:'/kyt/get_type/'+search_value+'/',method:'GET'});
        return data;
    },
    [SEARCH_ENS_LABEL_CLOUD]:async (store, search_input)=>{
        const { data }  = await SecondAPIInstance({url:'/accounts/prediction/'+search_input+'/20/',method:'GET'});
        return data;
    },
    [CHECK_BLOCKCHAINS]:async (store, search_input)=>{
        const { data }  = await ChainsAPIInstance({url:'/search/'+search_input,method:'GET'});
        return data;
    },
    [GET_SEARCH_EXAMPLES]:async ()=>{
        const { data }  = await DefaultAPIInstance({url:'/kyt/search_examples/',method:'GET'});
        return data;
    },
    [GET_STATISTICS]:async (store, current_page)=>{
        const { data }  = await DefaultAPIInstance({url:'/kyt/search_statistics/?page='+current_page,method:'GET'});
        return data;
    },
    [GET_HISTORY_BY_ID]:async (store, history_id)=>{
        const { data }  = await DefaultAPIInstance({url:'/kyt/search_history/'+history_id+'/',method:'GET'});
        return data;
    },
}

const mutations = {

}

const getters = {

}
export default {
    state,
    actions,
    mutations,
    getters
}
