import { createStore } from 'vuex'

// Modules
import AuthModule from './modules/AuthModule'
import SearchModule from './modules/SearchModule'

export default createStore({
  modules: {
      AuthModule,
      SearchModule
  }
})
