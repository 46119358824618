<template>
  <div class="asideInBlock">
    <p class="title" @click="this.menuOpened=!this.menuOpened"><i class="bi bi-list" v-if="this.is_mobile"></i>Navigation Bar</p>
    <ul class="menu" :class="this.menuOpened?'opened':''">
      <li>
        <a :class="this.activeNav === '1'?'active':''" href="/cabinet"><i class="bi bi-search"></i><span>Search</span></a>
      </li>
      <li>
        <a :class="this.activeNav === '2'?'active':''"><i class="bi bi-wallet"></i><span>Wallet Analytics</span></a>
      </li>
      <li>
        <a :class="this.activeNav === '3'?'active':''"><i class="bi bi-arrow-left-right"></i><span>Tx Explorer</span></a>
      </li>
      <li>
        <a :class="this.activeNav === '5'?'active':''"><i class="bi bi-shield"></i><span>Token Risk</span></a>
      </li>
      <li v-if="userInfo">
        <a :class="this.activeNav === '4'?'active':''" href="/cabinet/statistics"><i class="bi bi-key"></i><span>API Manager</span></a>
      </li>
      <li v-if="userInfo">
        <a @click="logoutFunc()" href="#"><i class="bi bi-box-arrow-right"></i><span>Logout</span></a>
      </li>
    </ul>
  </div>
</template>
<script>
import {ON_LOGOUT} from '@/store/modules/AuthModule';

export default {
  data:()=>({
    activeNav: '',
    is_mobile: false,
    menuOpened: false
  }),
  watch: {
    '$route'() {
      this.activeNav = this.$route.meta.activeNav;
    }
  },
  methods:{
    logoutFunc() {
      this.$store.dispatch(ON_LOGOUT);
    }
  },
  created() {
    this.activeNav = this.$route.meta.activeNav;
    if(screen.width <= 678){
      this.is_mobile = true;
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.get_user_info;
    }
  }
}
</script>
