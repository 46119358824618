import { createRouter, createWebHistory } from 'vue-router'
import PublicLayout from '../views/layouts/PublicLayout.vue';
import CabinetLayout from '../views/layouts/CabinetLayout.vue';

// eslint-disable-next-line no-prototype-builtins
const isAuthorized = localStorage.hasOwnProperty('token');
const authGuard = function (to, from, next){
  if(!isAuthorized) next({ name: 'Login' });
  else next()
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/default-pages/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/default-pages/Register.vue')
  },
  // {
  //   path: '/forgot-password',
  //   name: 'Forgot',
  //   component: () => import('../views/default-pages/ForgotPasswordView.vue')
  // },
  // {
  //   path: '/forgot-password-complete',
  //   name: 'ForgotComplete',
  //   component: () => import('../views/default-pages/ForgotPasswordComplete.vue')
  // },
  {
    path: '/set-password',
    name: 'SetPassword',
    component: () => import('../views/default-pages/SetPassword.vue')
  },
  // {
  //   path: '/set-password/:token',
  //   name: 'SetPasswordWithToken',
  //   component: () => import('../views/default-pages/SetPasswordToken.vue')
  // },
  {
    path: '/',
    name: 'LandingPage',
    component: () => import('../views/default-pages/LandingPage')
  },
  {path: "/:pathMatch(.*)*", component: () => import('../views/default-pages/NotFoundPage.vue')},
  {
    path: '/',
    component: PublicLayout,
    children: [
      {
        path: 'privacy-score',
        name: 'PrivacyScore',
        component: () => import('../views/default-pages/PrivacyScorePage')
      },
      {path: "/:pathMatch(.*)*", component: () => import('../views/default-pages/NotFoundPage.vue')}
    ],
  },
  {
    path: '/cabinet',
    component: CabinetLayout,
    // beforeEnter: authGuard,
    children: [
        {
          path: '',
          name: 'SearchPage',
          component: () => import('../views/cabinet-pages/SearchPage'),
          meta:{
            activeNav: '1'
          }
        },
        {
          path: 'address/:address',
          name: 'WalletAnalyticsPage',
          component: () => import('../views/cabinet-pages/WalletAnalyticsPage'),
          meta:{
            activeNav: '2'
          }
        },
        {
          path: 'transaction/:hash',
          name: 'TxExplorerPage',
          component: () => import('../views/cabinet-pages/TxExplorerPage'),
          meta:{
            activeNav: '3'
          }
        },
        {
          path: 'statistics',
          name: 'StatisticsPage',
          component: () => import('../views/cabinet-pages/StatisticsPage'),
          meta:{
            activeNav: '4'
          },
          beforeEnter: authGuard
        },
        {
          path: 'token/:address',
          name: 'TokenPage',
          component: () => import('../views/cabinet-pages/TokenPage'),
          meta:{
            activeNav: '5'
          }
        },
    ],
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  document.title = to.meta?.title ?? 'Oraclus | KYT - For AML/CFT Compliance';
  let link = document.createElement('link');
  link.setAttribute('rel', 'canonical');
  link.setAttribute('href', location.protocol + '//' + location.host + to.fullPath);
  document.head.appendChild(link);
})

export default router
